


























































































































































































































































import { getTxHref } from '@/chains'
import { Chain, Holding, InvestorPlatformClient, OrderAction, OrderCreate, OrderStatus, OrderType, SizeCategory } from '@/client'
import { delay } from '@/common'
import { useStore } from '@/store'
import { AxiosError } from 'axios'
import { Vue, Component } from 'vue-property-decorator'
import { DEXES } from '@/dexes'
import { ethers } from 'ethers'

@Component({})
export default class Trade extends Vue {
  buyOrSell: 'buy' | 'sell' = 'buy'
  orderType: 'limit' | 'market' = 'market'
  tradeType: 'solo' | 'shared' | 'other' = 'solo'
  chainId: number = 5
  slippage: '0.5' | '1' | '5' | '10' | null = '1'
  orderSize: '33%' | '50%' | '100%' = '33%'
  dexes = DEXES
  dex = this.dexes[0].routerAddress
  customTokenAddress = ''
  isOrderLoading = false
  holdings: Record<number, Holding[]> = {}
  selectedHolding: Holding | null = null
  buySize = 'Small'
  absoluteSize = ''
  chains: Chain[] = []
  active = true
  customSlippage = ''

  get orderSizeFraction() {
    const fraction = {
      numerator: 0,
      denominator: 100
    }
    if (this.orderSize == '33%') {
      fraction.numerator = 33
    } else if (this.orderSize == '50%') {
      fraction.numerator = 50
    } else if (this.orderSize == '100%') {
      fraction.numerator = 100
    }
    return fraction
  }

  get slippageFraction() {
    const fraction = {
      numerator: 0,
      denominator: 1000
    }
    if (this.slippage == '0.5') {
      fraction.numerator = 5
    } else if (this.slippage == '1') {
      fraction.numerator = 10
    } else if (this.slippage == '5') {
      fraction.numerator = 50
    } else if (this.slippage == '10') {
      fraction.numerator = 100
    }
    return fraction
  }

  get customSlippageFraction() {
    if (this.customSlippage === '') {
      return null
    }

    const customSlippageNumber = Number(this.customSlippage)
    if (customSlippageNumber < 0 || customSlippageNumber > 100) {
      return null
    }

    const fraction = {
      numerator: Math.round(customSlippageNumber * 10),
      denominator: 1000
    }
    return fraction
  }

  get sellableHoldings() {
    const chainHoldings = this.holdings[this.chainId]
    if (!chainHoldings) {
      return []
    }

    // TODO: filter by dex
    // TODO: exclude child orders
    return chainHoldings
      .filter(h => h.contract_address != null)
      .map(h => ({
        text: `${h.id} - ${h.name} (${h.symbol}) ${ethers.utils.formatUnits(h.balance, h.decimals)} - ${OrderType[h.order_type]}`,
        value: h
      }))
  }

  get currentChainDexes() {
    return this.getChainDexes(this.chainId)
  }

  get store() {
    return useStore()
  }

  async mounted() {
    while (this.active) {
      try {
        await this.sync()
      } catch (e) {
        console.error(e)
        Vue.config.errorHandler(e as any, this.$root, '')
      } finally {
        await delay(3000)
      }
    }
  }

  beforeDestroy() {
    this.active = false
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    this.active = false
    next()
  }

  async sync() {
    const client = InvestorPlatformClient.getFromStoredToken(process.env.VUE_APP_API_ENDPOINT!)
    const [chains, allHoldings] = await Promise.all([
      client.getChains(),
      client.getAllHoldings()
    ])
    this.chains = chains
    this.holdings = {}
    for (const holding of allHoldings) {
      if (!(holding.chain_id in this.holdings)) {
        this.holdings[holding.chain_id] = []
      }

      this.holdings[holding.chain_id].push(holding)
    }

    if (!allHoldings.find(h => h.id == this.selectedHolding?.id)) {
      this.selectedHolding = null
    }

    setTimeout(() => {
      if (this.selectedHolding) {
        return
      }

      if (this.sellableHoldings.length > 0) {
        this.selectedHolding = this.sellableHoldings[0].value
      }
    })
  }

  getChainDexes(chainId: number) {
    return this.dexes.filter(d => d.chainId == chainId)
  }

  async executeOrder() {
    const client = InvestorPlatformClient.getFromStoredToken(process.env.VUE_APP_API_ENDPOINT!)

    this.isOrderLoading = true
    try {
      let orderType: OrderType
      if (this.tradeType == 'solo') {
        orderType = OrderType.Solo
      } else if (this.tradeType == 'shared') {
        orderType = OrderType.Shared
      } else if (this.tradeType == 'other') {
        orderType = OrderType.Other
      } else {
        throw new Error('Unknown order type')
      }

      const orderSlippageFraction = this.customSlippageFraction ?? this.slippageFraction
      const orderCreate: OrderCreate = {
        chainId: this.chainId,
        dexRouterAddress: this.dex,
        slippagePercentNumerator: orderSlippageFraction.numerator,
        slippagePercentDenominator: orderSlippageFraction.denominator,
        orderType: orderType,
        orderAction: this.buyOrSell == 'buy' ? OrderAction.Buy : OrderAction.Sell
      }

      if (orderCreate.orderAction == OrderAction.Buy) {
        orderCreate.tokenContractAddress = this.customTokenAddress

        if (this.buySize == 'Small') {
          orderCreate.sizeCategory = SizeCategory.Small
        } else if (this.buySize == 'Medium') {
          orderCreate.sizeCategory = SizeCategory.Medium
        } else if (this.buySize == 'Large') {
          orderCreate.sizeCategory = SizeCategory.Large
        } else if (this.buySize == 'Custom') {
          // TODO: validation
          orderCreate.absoluteSize = this.absoluteSize
        } else {
          throw new Error('Unknown buy size')
        }
      } else if (orderCreate.orderAction == OrderAction.Sell) {
        orderCreate.holdingId = this.selectedHolding!.id
        orderCreate.sizePercentNumerator = this.orderSizeFraction.numerator
        orderCreate.sizePercentDenominator = this.orderSizeFraction.denominator
      }

      let order
      try {
        order = await client.executeTrade(orderCreate)
      } catch (e) {
        if (e instanceof AxiosError && e.response) {
          this.store.pushNotification(e.response.data.message)
        }
        console.error(e)
        return
      }
      while (order.status == OrderStatus.Open) {
        order = await client.getOrder(order.id)

        if (order.status == OrderStatus.Open) {
          await delay(2000)
        }
      }

      if (order.status == OrderStatus.Failed) {
        const href = order.transaction_hash ? getTxHref(order.transaction_hash, orderCreate.chainId) : undefined
        this.store.pushNotification(`Order failed: ${order.error_reason}`, href)
        return
      }

      const href = getTxHref(order.transaction_hash!, orderCreate.chainId)
      this.store.pushNotification(`Order filled`, href)
    } finally {
      this.isOrderLoading = false
    }
  }
}
